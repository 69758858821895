
import { defineComponent, PropType } from 'vue'
import {mapGetters} from "vuex";
import apiService from "@/api/api";

export default defineComponent({
  name: "Table",
  props: {
    // title: {type: Array as PropType<string[]>, default: () => []},
    tableData: {type: Array as PropType<{ [key: string]: any }>, default: () => []},
    tabKey: {type: String, default: ''}
  },
  data() {
    const tableDataKeys: {title: string, value: string, index: number}[] = []
    return {
      selectedIndex: -1,
      tableDataKeys
    }
  },
  computed: {
    ...mapGetters([
      'LANG'
    ]),
  },
  watch: {
    title: function () {
      // console.log('title watch')
      this.$forceUpdate()
    },
    tableData: function () {
      // console.log('TABledata watcher: ', JSON.stringify(this.tableData))
      this.selectedIndex = -1
      if (this.tableData.length === 0) {
        this.$emit('row-select', null)
        return
      }
      // console.log('TYPE IS', this.dataIsContacts())
      if (this.tabKey === 'c' || this.tabKey === 'g' || this.tabKey === 'l') {
        this.$emit('row-select', this.tableData[0])
      }
      const index = this.tableDataObjIndexForTitle()
      const keys = Object.keys(this.tableData[index])
      this.tableDataKeys = []
      let obj: {title: string, value: string, index: number} = {title: "", value: "", index: -1}
      for (let i = 0; i < keys.length; i++) {
        obj = {title: "", value: keys[i], index: i}
        switch (keys[i]) {
          // case "id":
          //   obj.title = "ID"
          //   this.tableDataKeys.push(obj)
          //   break
          case "name":
            obj.title = this.tabKey === 'p' ? 'Назва' : 'Iм\'я'
            this.tableDataKeys.push(obj)
            break
          case "email":
          case "login":
            obj.title = "Логiн"
            if (this.tabKey !== 'a') obj.title = "Ел. пошта"
            this.tableDataKeys.push(obj)
            break
          case "reg_date":
            obj.title = "Дата реєстрацiї"
            this.tableDataKeys.push(obj)
            break
          case "created":
            obj.title = "Створений"
            this.tableDataKeys.push(obj)
            break
          case "active":
            obj.title = "Видимiсть на сайтi"
            this.tableDataKeys.push(obj)
            break
          case "closed":
            obj.title = "Закритий"
            this.tableDataKeys.push(obj)
            break
          case "bank_link":
            obj.title = "Посилання на банку"
            this.tableDataKeys.push(obj)
            break
          case "bank_number":
            obj.title = "Номер банки"
            this.tableDataKeys.push(obj)
            break
          case "author_name":
            obj.title = "Автор"
            this.tableDataKeys.push(obj)
            break
          case 'role':
            obj.title = "Статус користувача"
            this.tableDataKeys.push(obj)
            break
          case 'image':
          case 'images':
          case 'avatar':
            obj.title = "Зображення"
            if (keys[i] === 'avatar') obj.title = 'Аватар'
            this.tableDataKeys.push(obj)
            break
          case 'title':
            obj.title = 'Заголовок'
            this.tableDataKeys.push(obj)
            break
          case 'text':
            obj.title = 'Текст'
            this.tableDataKeys.push(obj)
            break
          case 'price':
            obj.title = 'Цiна'
            this.tableDataKeys.push(obj)
            break
          case 'phone':
            obj.title = 'Телефон'
            this.tableDataKeys.push(obj)
            break
          case 'facebook':
            obj.title = 'Facebook'
            this.tableDataKeys.push(obj)
            break
          case 'instagram':
            obj.title = 'Instagram'
            this.tableDataKeys.push(obj)
            break
          case 'tiktok':
            obj.title = 'TikTok'
            this.tableDataKeys.push(obj)
            break
          case 'telegram':
            obj.title = 'Telegram'
            this.tableDataKeys.push(obj)
            break
          case 'permanent':
            obj.title = 'Постiйно необхiднi'
            this.tableDataKeys.push(obj)
            break
          case 'important':
            obj.title = 'Важливi'
            this.tableDataKeys.push(obj)
            break
          case 'desc':
          case 'description':
            obj.title = 'Опис'
            this.tableDataKeys.push(obj)
            break
          case 'video':
            obj.title = 'Biдео'
            this.tableDataKeys.push(obj)
            break
          case 'quantity':
            obj.title = 'Кiлькiсть'
            this.tableDataKeys.push(obj)
            break
          default:
        }
        if (i === keys.length - 1) {
          // console.log('TABLE KEYS', this.tableDataKeys)
          this.$forceUpdate()
        }
      }
      this.$forceUpdate()
    }
  },
  methods: {
    tableDataObjIndexForTitle(): number {
      let index = 0, maxKeysLength = 0
      let keys: string[] = []
      for (let i = 0; i < this.tableData.length; i++) {
        keys = Object.keys(this.tableData[i])
        if (keys.length > maxKeysLength) {
          index = i
          maxKeysLength = keys.length
        }
      }
      return index
    },
    showThisKey(key: string): boolean {
      let show = false
      for (let i = 0; i < this.tableDataKeys.length; i++) {
        if (key === this.tableDataKeys[i].value) show = true
      }
      // console.log('Show key: ', key, show)
      return show
    },
    getArray(data: any): string[] {
      if (!data) return []
      return data[this.LANG].split(',')
    },
    dataIsContacts(): boolean {
      return 'email' in this.tableData[0] && 'phone' in this.tableData[0] && 'facebook' in this.tableData[0] &&
          'instagram' in this.tableData[0] && 'tiktok' in this.tableData[0]
    },
    imgSrc(image: string): string {
      return apiService.getSrc(image, this.tabKey)
    },
    isImageKey(key: string): boolean {
      return key === 'image' || key === 'images' || key === 'avatar'
    },
    keysNumber(index: number): number {
      try {
        // console.log('data watch: ', index, JSON.stringify(this.tableData), this.tableData[index])
        if (this.tableData.length - 1 < index || this.tableData[index] == null) return 0
        const keys = Object.keys(this.tableData[index])
        return keys.length
      } catch (e) {
        console.error(e)
        return 0
      }
    },
    keyValue(outerIndex: number, innerIndex: number): any {
      try {
        const obj = this.tableData[outerIndex]
        if (obj == null) return 'error'
        const keys = Object.keys(obj)
        if (keys.length - 1 < innerIndex) return 'error'
        // if (keys[innerIndex] === 'price')
        return obj[keys[innerIndex]]
      } catch (e) {
        console.error(e)
        return 'error'
      }
    },
    keyIs(key: string, outerIndex: number, innerIndex?: number): boolean {
      // console.log('indexes: ', outerIndex, innerIndex)
      try {
        let keys: string[]
        let obj: any = null
        if (innerIndex != null && innerIndex >= 0) {
          obj = this.tableData[outerIndex]
          // console.log(obj)
          if (obj == null) return false
          keys = Object.keys(obj)
          // console.log(keys)
          if (keys.length - 1 < innerIndex) return false
          return keys[innerIndex] === key
        } else {
          console.error('Bad inner index')
          return false
        }
      } catch (e) {
        console.error(e)
        return false
      }
    },
    imageArray(images: string): string[] {
      if (!images) return []
      return images.split(',')
    },
    dataKeys(): string[] {
      if (!this.tableData.length) return []
      try {
        const keys = Object.keys(this.tableData[0])
        if (!keys.length) return []
        for (let i = 0; i < keys.length; i++) {
          keys[i] = keys[i].charAt(0).toUpperCase() + keys[i].slice(1)
          if (i === keys.length - 1) return keys
        }
        return keys
      } catch {
        return []
      }
    },
    emitButtonClick(data: any, edit?: boolean) {
      this.$emit('btn-click', {data, action: edit ? 'edit' : 'del'})
    },
    selectRow(index: number, data: any) {
      if (this.tabKey === 'c' || this.tabKey === 'g' || this.tabKey === 'l') return
      if (this.selectedIndex !== index) {
        this.selectedIndex = index
        this.$emit('row-select', data)
      } else {
        this.selectedIndex = -1
        this.$emit('row-select', null)
      }
    },
    statusString(status: number): string {
      if (status <= 0) return 'Недоступен для возврата или обмена'
      else {
        switch (status) {
          case 1:
            return 'Доступен обмен'
          case 2:
            return 'Доступен возврат'
          case 3:
            return 'Доступен возврат или обмен'
          default:
            return 'Неправильный статус'
        }
      }
    },
    roleString(role: number): string {
      switch (role) {
        case 0:
          return 'Гiсть'
        case 1:
          return 'Користувач'
        case 2:
          return 'Модератор'
        case 3:
          return 'Адмiн'
        case 4:
          return 'СуперАдмiн'
        default:
          return 'Неправильный статус'
      }
    },
    dateString(unixDate: number): string {
      const date = new Date(unixDate * 1000)
      return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
    }
  }
})
