import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8493608e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "table"
}
const _hoisted_2 = { class: "thead" }
const _hoisted_3 = { class: "tr head" }
const _hoisted_4 = { class: "tbody" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { key: 3 }
const _hoisted_10 = ["src"]
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.tableData.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableDataKeys, (key, i) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["th", {'images': _ctx.isImageKey(key.value), 'data-id': key.value === 'id'}]),
                key: 'th-' + i
              }, _toDisplayString(key.title), 3))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableData, (d, i) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["tr", {'selected': i === _ctx.selectedIndex}]),
              onClick: ($event: any) => (_ctx.selectRow(i, d)),
              key: 'tr-' + i
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableDataKeys, (dataKey) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(["td", {'images': _ctx.keyIs('images', i, dataKey.index) || _ctx.keyIs('image', i, dataKey.index) || _ctx.keyIs('avatar', i, dataKey.index),
        'data-id': dataKey.value === 'id', 'text': _ctx.keyIs('text', i, dataKey.index) || _ctx.keyIs('description', i, dataKey.index),
         'a-false': _ctx.keyIs('active', i, dataKey.index) && !d.active || _ctx.keyIs('closed', i, dataKey.index) && !d.closed,
         'a-true': _ctx.keyIs('active', i, dataKey.index) && d.active || _ctx.keyIs('closed', i, dataKey.index) && d.closed}]),
                  key: 'td-' + (dataKey.index)
                }, [
                  (_ctx.keyIs('images', i, dataKey.index) || _ctx.keyIs('image', i, dataKey.index) || _ctx.keyIs('avatar', i, dataKey.index))
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.imageArray(d.images != null ? d.images : d.image != null ? d.image : d.avatar), (img, i) => {
                        return (_openBlock(), _createElementBlock("img", {
                          src: _ctx.imgSrc(img),
                          alt: "",
                          key: 'tbl-img-' + i
                        }, null, 8, _hoisted_6))
                      }), 128))
                    : (_ctx.keyIs('status', i, dataKey.index))
                      ? (_openBlock(), _createElementBlock("b", _hoisted_7, _toDisplayString(_ctx.statusString(_ctx.keyValue(i, dataKey.index))), 1))
                      : (_ctx.keyIs('role', i, dataKey.index))
                        ? (_openBlock(), _createElementBlock("b", _hoisted_8, _toDisplayString(_ctx.roleString(_ctx.keyValue(i, dataKey.index))), 1))
                        : (_ctx.keyIs('reg_date', i, dataKey.index) || _ctx.keyIs('created', i, dataKey.index))
                          ? (_openBlock(), _createElementBlock("b", _hoisted_9, _toDisplayString(_ctx.dateString(_ctx.keyValue(i, dataKey.index))), 1))
                          : (_ctx.keyIs('permanent', i, dataKey.index) || _ctx.keyIs('important', i, dataKey.index))
                            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 4 }, _renderList(_ctx.getArray(_ctx.keyValue(i, dataKey.index)), (val, i) => {
                                return (_openBlock(), _createElementBlock("b", { key: i }, _toDisplayString(val), 1))
                              }), 128))
                            : (_ctx.keyIs('video', i, dataKey.index))
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                                  (_ctx.keyValue(i, dataKey.index))
                                    ? (_openBlock(), _createElementBlock("video", {
                                        key: 0,
                                        src: _ctx.imgSrc(_ctx.keyValue(i, dataKey.index)),
                                        width: "150",
                                        height: "100",
                                        controls: "",
                                        muted: "",
                                        autoplay: ""
                                      }, null, 8, _hoisted_10))
                                    : (_openBlock(), _createElementBlock("b", _hoisted_11, "Вiдео вiдсутнє"))
                                ], 64))
                              : (!_ctx.keyIs('active', i, dataKey.index) && !_ctx.keyIs('closed', i, dataKey.index) && !_ctx.keyIs('video', i, dataKey.index) && _ctx.showThisKey(dataKey.value))
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 6 }, [
                                    (_ctx.keyIs('title', i, dataKey.index) || _ctx.keyIs('text', i, dataKey.index) || _ctx.keyIs('description', i, dataKey.index) || (_ctx.keyIs('name', i, dataKey.index) && _ctx.tabKey !== 'a'))
                                      ? (_openBlock(), _createElementBlock("b", _hoisted_12, _toDisplayString(_ctx.keyValue(i, dataKey.index)[_ctx.LANG]), 1))
                                      : (_openBlock(), _createElementBlock("b", _hoisted_13, _toDisplayString(_ctx.keyValue(i, dataKey.index)), 1))
                                  ], 64))
                                : _createCommentVNode("", true)
                ], 2))
              }), 128))
            ], 10, _hoisted_5))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}